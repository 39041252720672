
import { IonAlert, IonPage, IonContent } from '@ionic/vue';
import { close } from 'ionicons/icons'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'Login',
  components: { 
    IonAlert,
    IonContent, 
    IonPage 
  },
  data () {
    return {
      email: '',
      password: '',
      loginError: false,
      showCreateAccountModal: false,
      buttons: [
        {
          text: 'Nein, danke',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            localStorage.setItem('createAccountModalShown', 'true');
          }
        },
        {
          text: 'Los geht\'s',
          handler: () => {
            localStorage.setItem('createAccountModalShown', 'true');
            (this as any).$router.push('/auth/signup');
          }
        }
      ],
      close
    }
  },
  computed: {
    hasError (): boolean {
      return (this as any).loginError;
    }
  },
  methods: {
    goHome () {
      (this as any).$router.push('/');
    },
    async submit () {
      const vm = (this as any);
      if (vm.email.length && vm.password.length) {

        api.login(vm.email, vm.password)
          .then((result: any) => {
            if (result.status === 200 && result.data?.access_token.length) {
              localStorage.setItem('email', vm.email);
              localStorage.setItem('jwt', result.data.access_token);
              localStorage.setItem('jwt_expires', result.data.expires_at);
              localStorage.removeItem('fcmSubscribed');

              if (!localStorage.getItem('firstLoginEventTriggered')) {
                // @ts-ignore
                let _mtm = window._mtm || [];
                localStorage.setItem('firstLoginEventTriggered', '1');
                // https://webstatistik.uk-koeln.de/piwik.php?e_c=Auth&e_a=FirstLogin&e_n=1&e_v=1&ca=1&idsite=103&rec=1&r=152352&h=12&m=34&s=41&url=https%3A%2F%2Fapp.uk-aktuell.sunzity.de%2Fnews&urlref=https%3A%2F%2Fapp.uk-aktuell.sunzity.de%2Fnews%3FmtmPreviewMode%3DWp55JRdB&_id=d83cbe10a609af69&_idn=0&_refts=0&send_image=0&pdf=1&qt=0&realp=0&wma=0&fla=0&java=0&ag=0&cookie=1&res=1440x900&pv_id=gGOLJN
                // @ts-ignore
                _mtm.push({"event": "first-login"})
              }

              // vm.$router.push('/');
              location.href = '/?logged-in'
            } 
          })
          .catch((error: any) => {
            vm.loginError = true;
            console.log(error);
          });
      }
    },
    setCreateAccountModalOpen (value: boolean) {
      (this as any).showCreateAccountModal = value;
    }
  },
  mounted () {
    if (!localStorage.getItem('createAccountModalShown')) {
      (this as any).setCreateAccountModalOpen(true);
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave login');
  },
  ionViewDidEnter () {
    console.log('[ionView] entered login');
  }
}
